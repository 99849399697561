import React from 'react';
import './StaffMember.scss';
/**
 * @namespace Staff
 * @function Staff
 * @author Trevor Cash
 * @since 11/06/21
 * @version 1.0.0
 * @component
 */
export default function Staff({ image, alt, hotspot, name, position, blurb }) {
  console.log(hotspot);
  return (
    <div className='Staff-Member'>
      <img
        className='Staff-Member-Photo'
        style={{ objectPosition: `${hotspot.x * 100}% ${hotspot.y * 100}%` }}
        src={image}
        alt={alt}
        loading='lazy'
      />
      <h3 className='Staff-Member-Name'>{name}</h3>
      <span className='Staff-Member-Position'>{position}</span>
      <div className='Staff-Member-Blurb'>{blurb}</div>
    </div>
  );
}
