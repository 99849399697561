import React from 'react';
import './About.scss';
import Layout from '../../Components/Layout/Layout';
import StaffMember from '../../Components/Staff/StaffMember';
import Link from '../../Components/Link/Link';
import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';

/**
 * @namespace About
 * @function About
 * @author Trevor Cash
 * @since 10/21/21
 * @version 1.0.0
 * @component
 */
export default function About() {
  const data = useStaticQuery(graphql`
    query aboutQuery {
      allSanityMembers(sort: { fields: orderRank }) {
        nodes {
          name
          title
          _rawBio
          image {
            asset {
              url
            }
            hotspot {
              y
              x
            }
          }
        }
      }
      allSanityAbout {
        nodes {
          beliefsLink
          _rawBeliefs
          _rawHistory
          historyLink
        }
      }
    }
  `);

  const members = data?.allSanityMembers?.nodes;
  const about = data?.allSanityAbout?.nodes?.[0];
  return (
    <Layout
      className='About-Container'
      title='About'
      description='Our core values are revealed through our actions, faith and beliefs. Our beliefs provide us with direction in life and give us the opportunity to define our responsibilities.'
    >
      <h1>About Us</h1>
      <h2>Our Team</h2>
      <div className='Staff'>
        {members?.map((member, index) => (
          <StaffMember
            key={index}
            name={member?.name}
            position={member?.title}
            image={member?.image?.asset?.url}
            hotspot={{
              x: member?.image?.hotspot?.x,
              y: member?.image?.hotspot?.y,
            }}
            alt={member?.title}
            blurb={member?._rawBio && <PortableText value={member?._rawBio} />}
          />
        ))}
      </div>
      <div className='Beliefs'>
        <h2>Our Beliefs</h2>
        {about?._rawBeliefs && <PortableText value={about?._rawBeliefs} />}
        <Link external to={about?.beliefsLink}>
          Click to View
        </Link>
      </div>
      <div className='History'>
        <h2>Our History</h2>
        {about?._rawHistory && <PortableText value={about?._rawHistory} />}
        <Link external to={about?.historyLink}>
          Click to View
        </Link>
      </div>
    </Layout>
  );
}
